<template>
    <main
        class="mt-10 md:mt-1 flex flex-col-reverse gap-8 items-center md:flex-row md:gap-16 md:justify-center min-h-[65vh] md:min-h-[80vh]">
        <div class="space-y-2 text-center md:text-left px-10">
            <p class="text-white">Hello World, I'm</p>
            <h1 class="text-4xl font-bold md:text-5xl text-white fadein-up">Yichen Liu
                <span class="wave">👋🏼</span>
            </h1>
            <div class="py-2">
                <h1 class="typewrite text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-slate-200 to-indigo-800 md:text-2xl fadein-up"
                    ref="typewriter">
                    <span class="wrap">{{ txt }}</span>
                </h1>
            </div>
            <br>

            <a href="\cv_eng.pdf" target="_blank" class="fadein-bot fade-500 flex items-center py-2 px-4 mx-auto text-sm font-medium rounded-lg border transition duration-300 md:py-2.5 md:px-5 md:mx-0 text-white border-white hover:bg-amber-200 hover:bg-opacity-10 bg-transparent focus:outline-none w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mr-2 w-4 h-4">
                    <path fill-rule="evenodd"
                        d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z"
                        clip-rule="evenodd"></path>
                    <path
                        d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z">
                    </path>
                </svg>
                下载简历</a>

        </div>
        <div class="flex justify-center md:justify-start fadein-right">
            <img alt="avatar" fetchpriority="high" width="300" height="300" decoding="async" loading="eager" 
            class="w-10/12 max-w-[300px] md:h-auto rounded-full border-4 border-white pict object-cover aspect-square"
            src="/img/avatar.jpg" 
            >
        </div>
    </main>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            toRotate: ["Full-stack Developer", "Tech Enthusiast"],
            period: 2000,
            txt: '',
            loopNum: 0,
            isDeleting: false,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.tick();
        });
    },
    methods: {
        tick() {
            let typewriter = this.$refs.typewriter;

            if (!typewriter) {
                return; // 如果无法获取 typewriter 元素，则结束函数
            }

            let i = this.loopNum % this.toRotate.length;
            let fullTxt = this.toRotate[i];

            // 根据当前状态，更新文本内容
            this.txt = this.isDeleting ? fullTxt.substring(0, this.txt.length - 1) : fullTxt.substring(0, this.txt.length + 1);
            typewriter.innerHTML = `<span class="wrap">${this.txt}</span>`;

            let that = this;
            let delta = 200 - Math.random() * 100;

            if (this.isDeleting) {
                delta /= 2; // 如果正在删除文本，则减少延迟时间
            }

            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period; // 如果已经完全显示文本，则将延迟时间设置为固定的周期
                this.isDeleting = true; // 切换到删除文本的状态
            } else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false; // 切换回添加文本的状态
                this.loopNum++; // 增加循环次数
                delta = 500; // 设置删除完整文本后的延迟时间
            }

            setTimeout(() => {
                that.tick(); // 递归调用 tick() 函数，进行下一次文本更新
            }, delta);
        }

    }
}
</script>

<style>
body {
    overflow-y: scroll;
    overflow-x: hidden;
}

.typewrite>.wrap {
    border-right: 0.08em solid #fff;
}

.wave {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg)
    }

    10% {
        transform: rotate(14deg)
    }

    20% {
        transform: rotate(-8deg)
    }

    30% {
        transform: rotate(14deg)
    }

    40% {
        transform: rotate(-4deg)
    }

    50% {
        transform: rotate(10deg)
    }

    60% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(0deg)
    }
}

.pict {
    box-shadow: 0px 0px 73px -9px rgba(71, 133, 248, 0.44);
    -webkit-box-shadow: 0px 0px 73px -9px rgba(71, 133, 248, 0.44);
    -moz-box-shadow: 0px 0px 73px -9px rgba(71, 133, 248, 0.44);
}

.fadein-up {
    opacity: 0;
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 15px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fade-in-from-left {
    opacity: 0;
    animation: fadeInLeft 0.5s ease-out forwards;
    animation-delay: 500ms;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadein-right {
    opacity: 0;
    animation: fadeInRight 0.5s ease-out forwards;
    animation-delay: 500ms;
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadein-bot {
    opacity: 0;
    animation: fadeInBot 0.5s forwards;
}

@keyframes fadeInBot {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadein-1 {
    animation-delay: 200ms;
}

.fadein-2 {
    animation-delay: 400ms;
}

.fadein-3 {
    animation-delay: 600ms;
}

.fade-500 {
    animation-delay: 500ms;
}
</style>
